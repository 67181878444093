<template>
  <el-select
  :value="selectedValue"
  @change="selectChanged($event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
  export default {
    props: {
      selectedValue: Number,
      persentValues: Array
    },
    data() {
      return {
        options: this.persentValues
      }
    },
    methods: {
      selectChanged(value) {
        this.$emit('selectChanged', value)
      }
    }
  }
</script>
