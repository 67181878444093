import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const baseUrl = 'https://online.belokurikha.ru'
//const baseUrl = 'http://online-demo.resortshop.ru'
const config = {
  moduleKatun: {
    siteUrl: 'https://www.katun-san.ru',
    division: 'e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2',
  },
  moduleSibir: { 
    siteUrl: 'https://www.sibir-san.ru', 
    division: 'dcb6fbcb-7009-47ff-baad-c0027817ddbc',
  },
  moduleBelokurikha: { 
    siteUrl: 'https://www.belokurikha-san.ru', 
    division: '70967e77-a573-4294-a69f-17648bd1b003',
  },
    moduleLocal: {
    siteUrl: 'http://module-iframe.lc',
    division: 'e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2',
  },
  moduleAll: { 
    siteUrl: 'https://belokurikha.ru', 
    division: null 
  },
  
}

let clientModuleName = window.name 
if (clientModuleName === "") {
  clientModuleName = 'moduleKatun'
}  
// let clientModuleName = 'moduleKatun'

export default new Vuex.Store({
  
  state: {
    
    apiKey: "b31873bc42b54ee8996405c0d16e7933",
    session: null,
    sessionUrl: `${baseUrl}/client_data/session`,
    configUrl: `${baseUrl}/client_data/config`,
    searchUrl: `${baseUrl}/client_data/search`,
    addRoomUrl: `${baseUrl}/client_data/add_room`,
    saveCartUrl: `${baseUrl}/client_data/save_cart`,
    paymentTypeUrl: `${baseUrl}/client_data/payment_type`,
    paymentUrl: `${baseUrl}/client_data/payment`,
    removeUrl: `${baseUrl}/client_data/cancel_room`,
    step: 1,
    division: config[clientModuleName].division,
    //division: "b68673d4-52cb-42f6-9942-7bb86639c628",
	// division: '70967e77-a573-4294-a69f-17648bd1b003', // 'Санаторий "Белокуриха"'
	//division: 'e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2', // 'Санаторий "Катунь"'
    // division: 'dcb6fbcb-7009-47ff-baad-c0027817ddbc', // 'Санаторий "Сибирь"'
    // division: null,
    guests: null,
    // startDate: "Mon May 10 2021 00:00:00 GMT+0700 (GMT+07:00)",
    startDate: "",
    // endDate: "Mon May 17 2021 00:00:00 GMT+0700 (GMT+07:00)",
    endDate: "",
    endDateReal: "",
    endDatePrev: "",
    roomPrice: null,
    //days: 7,
    days: null,
    configData: null,
    searchData: [],
    rooms: null,
    tariffs: null,
    guestParams: [
      {
          id: 1,
          adults: {quantity: 1, isDop: [], tariffIds: []},
          childs : {ages: [], isDop: [], tariffIds: []},
          roomId: null,
          descZdrav: null
      }
    ],

    bookingInfo: "",
    nightsInfo: "",
    //backend
    backApiUrl:  'https://admin.booking-belokurikha.ru/api',
    // backApiUrl:  'http://127.0.0.1:8000/api',
    backendRooms: null,
    backendTariffs: null,
    addedRooms: null,
    prices: [],
    test: null,
    settings: null,
    informationPages: null,
    // parentSiteUrl: "http://dev6.citportal.ru",
    parentSiteUrl: config[clientModuleName].siteUrl,
    //parentSiteUrl: "*",
    //sendPulse
    sendpulseToken: null,
    tokenTime: null,
    otherRooms: null
  },
  mutations: {
    SET_START_DATE: (state, newDate) => {
      state.startDate = newDate
    },
    SET_END_DATE: (state, newDate) => {
      state.endDate = newDate
    },
    SET_DAYS: (state, newDays) => {
      state.days = newDays
    },
    SET_SESSION: (state, newSession) => {
      state.session = newSession
    },
    SET_CONFIG_DATA: (state, newConfigData) => {
      state.configData = newConfigData
    },
    SET_SEARCH_DATA: (state, newSearchData) => {
      state.searchData = newSearchData
    },
    SET_SEARCH_GUEST: (state, newGuest) => {
      state.guests = newGuest
    },
    SET_DIVISION: (state, newDivison) => {
      state.division = newDivison
    },
    SET_STEP: (state, newStep) => {
      state.step = newStep
    },
    SET_GUEST_PARAMS: (state, newParams) => {
      state.guestParams = newParams
    },
    SET_BK_ROOMS: (state, newRooms) => {
      state.backendRooms = newRooms
    },
    SET_ROOMS: (state, newRooms) => {
      state.rooms = newRooms
    },
    SET_ADDED_ROOMS: (state, newRooms) => {
      state.addedRooms = newRooms
    },
    SET_TARIFFS: (state, newTariffs) => {
      state.tariffs = newTariffs
    },
    SET_GUESTS: (state, newGuests) => {
      state.guests = newGuests
    },
    SET_BOOKING_INFO: (state, newBookingInfo) => {
      state.bookingInfo = newBookingInfo
    },
    SET_NIGHTS_INFO: (state, newNightsInfo) => {
      state.nightsInfo = newNightsInfo
    },
    SET_END_DATE_REAL: (state, newDate) => {
      state.endDateReal = newDate
    },
    SET_END_DATE_PREV: (state, newDate) => {
      state.endDatePrev = newDate
    },
    SET_ROOM_PRICE: (state, newPrice) => {
      state.roomPrice = newPrice
    },
    SET_BACKEND_ROOMS: (state, newRooms) => {
      state.backendRooms = newRooms
    },
    SET_BACKEND_TARIFFS: (state, newTariffs) => {
      state.backendTariffs = newTariffs
    },
    SET_PRICES: (state, newPrices) => {
      state.prices = newPrices
    }, 
    SET_SETTINGS: (state, newSettings) => {
      state.settings = newSettings
    },
    SET_INFORMATION_PAGES: (state, newInformationPages) => {
      state.informationPages = newInformationPages
    },
    SET_SENDPULSE_TOKEN: (state, newToken) => {
      state.sendpulseToken = newToken
    },
    SET_TOKEN_TIME: (state, newTime) => {
      state.tokenTime = newTime
    },
    SET_OTHER_ROOMS: (state, newRooms) => {
      state.otherRooms = newRooms
    },
  },
  actions: {
    SET_START_DATE({ commit }, newDate) {
      commit('SET_START_DATE', newDate)
    },
    SET_END_DATE({ commit }, newDate) {
      commit('SET_END_DATE', newDate)
    },
    SET_DAYS({ commit }, newDays) {
      commit('SET_DAYS', newDays)
    },
    SET_SEARCH_GUEST({ commit }, newGuest) {
      commit('SET_SEARCH_GUEST', newGuest)
    },
    SET_DIVISION({ commit }, newDivision) {
      commit('SET_DIVISION', newDivision)
    },
    SET_STEP({ commit }, newStep) {
      commit('SET_STEP', newStep)
    },
    SET_GUEST_PARAMS({ commit }, newParams) {
      commit('SET_GUEST_PARAMS', newParams)
    },
    SET_SESSION({ commit }, newSession) {
      commit('SET_SESSION', newSession)
    },
    SET_CONFIG_DATA({ commit }, newConfigData) {
      commit('SET_CONFIG_DATA', newConfigData)
    },
    SET_SEARCH_DATA({ commit }, newSearchData) {
      commit('SET_SEARCH_DATA', newSearchData)
    },
    SET_BK_ROOMS({ commit }, newRooms) {
      commit('SET_BK_ROOMS', newRooms)
    },
    SET_GUESTS({ commit }, newGuests) {
      commit('SET_GUESTS', newGuests)
    },
    SET_BOOKING_INFO({ commit }, newBookingInfo) {
      commit('SET_BOOKING_INFO', newBookingInfo)
    },
    SET_NIGHTS_INFO({ commit }, newNightsInfo) {
      commit('SET_NIGHTS_INFO', newNightsInfo)
    },
    SET_ROOMS({ commit }, newRooms) {
      commit('SET_ROOMS', newRooms)
    },
    SET_ADDED_ROOMS({ commit }, newRooms) {
      commit('SET_ADDED_ROOMS', newRooms)
    },
    SET_TARIFFS({ commit }, newTariffs) {
      commit('SET_TARIFFS', newTariffs)
    },
    SET_ROOM_PRICE({ commit }, newPrice) {
      commit('SET_ROOM_PRICE', newPrice)
    },
    SET_END_DATE_REAL({ commit }, newDate) {
      commit('SET_END_DATE_REAL', newDate)
    },
    SET_END_DATE_PREV({ commit }, newDate) {
      commit('SET_END_DATE_PREV', newDate)
    },
    SET_BACKEND_ROOMS({ commit }, newRooms) {
      commit('SET_BACKEND_ROOMS', newRooms)
    },
    SET_BACKEND_TARIFFS({ commit }, newTariffs) {
      commit('SET_BACKEND_TARIFFS', newTariffs)
    },
    SET_PRICES({ commit }, newPrices) {
      commit('SET_PRICES', newPrices)
    },
    SET_SETTINGS({ commit }, newSettings) {
      commit('SET_SETTINGS', newSettings)
    },
    SET_INFORMATION_PAGES({ commit }, newInformationPages) {
      commit('SET_INFORMATION_PAGES', newInformationPages)
    },
    SET_SENDPULSE_TOKEN: ({ commit }, newToken) => {
      commit('SET_SENDPULSE_TOKEN', newToken)
    },
    SET_TOKEN_TIME: ({commit}, newTime) => {
      commit('SET_TOKEN_TIME', newTime)
    },
    SET_OTHER_ROOMS({ commit }, newRooms) {
      commit('SET_OTHER_ROOMS', newRooms)
    },
  },
  getters: {
    DAYS(state) {
      return state.days
    },
    START_DATE(state) {
      return state.startDate
    },
    END_DATE(state) {
      return state.endDate
    },
    ROOMS_STATE(state) {
      return state.searchData.availableRoom
    },
    STEP_STATE(state) {
      return state.step
    },
    GUEST_PARAMS(state) {
      return state.guestParams
    },
    API_KEY(state) {
      return state.apiKey
    },
    SESSION_URL(state) {
      return state.sessionUrl
    },
    SEARCH_URL(state) {
      return state.searchUrl
    },
    CONFIG_URL(state) {
      return state.configUrl
    },
    ADD_ROOM_URL(state) {
      return state.addRoomUrl
    },
    SAVE_CART_URL(state) {
      return state.saveCartUrl
    },
    PAYMENT_TYPE_URL(state) {
      return state.paymentTypeUrl
    },
    PAYMENT_URL(state) {
      return state.paymentUrl
    },
    REMOVE_URL(state) {
      return state.removeUrl
    },
    SESSION_DATA(state) {
      return state.session
    },
    DIVISION(state) {
      return state.division
    },
    GUESTS(state) {
      return state.guests
    },
    BACKEND_ROOMS(state) {
      return state.backendRooms
    },
    BOOKING_INFO(state) {
      return state.bookingInfo
    },
    NIGHTS_INFO(state) {
      return state.nightsInfo
    },
    ROOMS(state) {
      return state.rooms
    },
    ADDED_ROOMS(state) {
      return state.addedRooms
    },
    SEARCH_DATA(state) {
      return state.searchData
    },
    END_DATE_REAL(state) {
      return state.endDateReal
    },
    END_DATE_PREV(state) {
      return state.endDatePrev
    },
    TARIFFS(state) {
      return state.tariffs
    },
    BACK_API_URL(state) {
      return state.backApiUrl
    },
    BACKEND_TARIFFS(state) {
      return state.backendTariffs
    },
    PRICES(state) {
      return state.prices
    },
    SETTINGS(state) {
      return state.settings
    },
    INFORMATION_PAGES(state) {
      return state.informationPages
    },
    PARENT_SITE_URL(state) {
      return state.parentSiteUrl
    }
  },
  modules: {
  }
})
